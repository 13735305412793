



















































































































































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import { BookMuluText } from "@/utils/base";
import { GetYuanwenList, Getchecker } from "@/request/check";
import { SearchBook, GetBookZhangjie } from "@/request/mark";
import MainHeader from "@/components/main-header/main-header.vue";
import YuanwenHengji from "../../components/hengji/yuanwen-hengji.vue";
@Component({
  components: {
    MainHeader,
    YuanwenHengji,
  },
})
export default class Name extends mixins(listMixin, quanxianMixin) {
  private data: any[] = [];
  private editData: any = {};
  private ifShowHengji: any = false;
  private hengjiData: any = [];
  private hengjiId: any = "";
  private types: any = ["古籍"];
  private status: any = ["未校验", "校验中", "已校验"];
  private jiaoyanyuan: any = [];
  private shumings: any = [];
  private zhangjies: any = [];
  private cancelHengji() {
    this.ifShowHengji = false;
  }
  private getBookMuluText(item: any) {
    return BookMuluText(item);
  }
  private shumingFocus() {
    this.remoteShumingMethod("");
  }
  private remoteShumingMethod(e: any) {
    const params: any = {
      params: {
        search: e,
      },
    };
    SearchBook(this, params).then((res: any) => {
      this.shumings = res;
    });
  }
  private selectBook(e: any, ifMounted?: any) {
    const params: any = {
      book_id: e._id,
    };
    GetBookZhangjie(this, params).then((res: any) => {
      this.zhangjies = res;
      if (!ifMounted) {
        this.filters.title = {};
      }
      this.getList();
    });
  }
  private zhangjieChange(e: any) {
    this.filters.title = e;
    this.$forceUpdate();
    this.getList();
  }
  /**
   * @description 跳转编辑
   */
  private jumpCompose(item: any) {
    if (!this.getIsShowQuanxian("原文校验校验") || item["锁定"]) {
      return;
    }
    this.$store.commit("updateIfCompose", true);
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/compose",
      query: {
        id: item.title_id,
      },
    });
  }
  private goRead(item: any) {
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/read",
      query: {
        id: item.id,
      },
    });
  }
  private openHengji(item: any) {
    if (!this.getIsShowQuanxian("原文校验查看校验痕迹")) {
      return;
    }
    this.hengjiId = item.title_id;
    this.ifShowHengji = true;
  }
  /**
   * @description 获取用户列表
   */
  getList() {
    const params: any = {
      params: {
        search: this.filters.book._id,
        title: this.filters.title._id,
        校验状态: this.filters.status,
        user_id: this.filters["校验员"].id,
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
      },
    };
    localStorage.setItem(
      "yuanwenjiaoyanliFilter",
      JSON.stringify(this.filters)
    );
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetYuanwenList(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.data = data.data;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private getChecker() {
    const params: any = {
      params: {
        kind: "原文校验",
      },
    };
    Getchecker(this, params).then((res: any) => {
      this.jiaoyanyuan = res;
    });
  }
  /**
   * @description 初始化
   */
  mounted() {
    this.filters.sort_field = "更新时间";
    this.filters.type = "";
    this.filters.status = "";
    this.filters.book = {};
    this.filters.title = {};
    this.filters["校验员"] = {};
    
    const d = localStorage.getItem("yuanwenjiaoyanliFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
      if (this.filters.book && this.filters.book._id) {
        this.selectBook(this.filters.book, true);
      }
    }
    this.getList();
    this.getChecker();
    this.remoteShumingMethod("");
  }
}
