























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { GetBookLog } from "@/request/check";
@Component({})
export default class Name extends mixins(listMixin) {
  @Prop()
  private title_id: any;
  @Prop()
  private book_id: any;
  @Prop()
  private kind: any;
  @Prop()
  private ifShow: any;
  @Watch("ifShow", { immediate: true })
  private ifShowChange() {
    this.search();
  }
  private data: any = [];
  private get ifShowHengji() {
    return this.ifShow;
  }
  private set ifShowHengji(val: any) {
    if (!val) {
      this.cancel();
    }
  }
  private cancel() {
    this.$emit("cancelHengji");
  }
  private goRead(item: any) {
    this.$router.push({
      path: "/main/check/yuanwenjiaoyan/read",
      query: {
        id: item.id,
      },
    });
  }
  getList() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        title_id: this.title_id,
        book_id: this.book_id,
        current_page: this.filters.current_page,
        page_num: this.filters.page_count,
      },
    };
    GetBookLog(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.data = res.data;
        this.filters.total_count = res.total_count;
        this.filters.total_page = res.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
}
